import React from 'react';
import type { PageProps } from 'gatsby';

import ChatPage from '../../views/Chat/ChatPage';
import PageLayout from '../../views/Layout/Page';
import MetaTags from '../../views/MetaTags';

export default function MessagesDetailPage({ params }: PageProps) {
  return (
    <PageLayout hideFooter hideTopBanner pageview="message_thread" private>
      <MetaTags title="messages.meta.title" />
      <ChatPage selectedChat={params.chat} />
    </PageLayout>
  );
}
